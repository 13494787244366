import React from 'react';

import Styles from './location-banner.module.scss';

export default () => (
  
  <section className={Styles.locationSection}>
    <iframe 
      title="Radium map"
      src=
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d745.71399489619!2d-0.13977647381919817!3d51.511692178713865!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51e65a42e363778a!2sRadium+Digital+Agency!5e0!3m2!1sen!2suk!4v1556617312272!5m2!1sen!2suk" 
      className={Styles.map}
      frameBorder="0" 
      allowFullScreen
    ></iframe>
  </section>
);
