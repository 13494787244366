import React from 'react';

/**
 * Images/SVG
 */
import ReagentStreet from '../images/regent-st.png';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Location from '../components/location-banner/location-banner';
import Adress from '../components/adress/adress';
import FormContainer from '../components/form-container/form-container';

export default ( props ) => {

  const adressData = { img: <img alt="Building" src={ReagentStreet} /> };

  return (
    <Layout>
      <SEO 
        title="Contact" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `Contact` ]} 
      />
      <Location />
      <Adress {...adressData}/>
      <FormContainer />
    </Layout>
  )
};
