import React from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";

import Styles from './adress.module.scss';

export default ({ img }) => (
  
  <section className={Styles.adressSection}>
    <div className='container'>
      <div className={Styles.adressContainer}>
        <div className={Styles.adressTextContainer}>
          <h2 className={Styles.adressTitle}>Let's Chat</h2>
          <p className={Styles.walkthrough}>
            We consistently receive thank you messages from those we have worked for – This is the best endorsement we could ever hope for! Wouldn’t you rather use our services? We are ready and waiting!
          </p>
          <h2 className={Styles.adressCity}>London <FaMapMarkerAlt /></h2>
          <a 
            href="https://goo.gl/maps/bTbKCB9BMDkz9rRT6" 
            target="_blank" 
            rel="noopener noreferrer"
            className={Styles.adressLink}
          >
            <p className={Styles.adressLine}>Room 330</p>
            <p className={Styles.adressLine}>Linen hall 162-168</p>
            <p className={Styles.adressLine}>Regent Street, W1B 5TB</p>
          </a>
        </div>
        <div className={Styles.adressOuterImgContainer}>
          <div className={Styles.adressInnerImgContainer}>
            {img}
          </div>
        </div>
      </div>
    </div>
  </section>
);
